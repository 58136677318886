@charset "UTF-8";
@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes transY {
  0% {
    margin-top: -16px;
  }
  100% {
    margin-top: 0;
  }
}
@keyframes rotate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loading-text-opacity {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
*:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
  all: unset;
  display: revert;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

a, button {
  cursor: revert;
}

ol, ul, menu {
  list-style: none;
}

img {
  max-inline-size: 100%;
  max-block-size: 100%;
}

table {
  border-collapse: collapse;
}

input, textarea {
  -webkit-user-select: auto;
}

textarea {
  white-space: revert;
}

meter {
  -webkit-appearance: revert;
  appearance: revert;
}

:where(pre) {
  all: revert;
  box-sizing: border-box;
}

::placeholder {
  color: unset;
}

::marker {
  content: initial;
}

:where([hidden]) {
  display: none;
}

:where([contenteditable]:not([contenteditable=false])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  -webkit-user-select: auto;
}

:where([draggable=true]) {
  -webkit-user-drag: element;
}

:where(dialog:modal) {
  all: revert;
  box-sizing: border-box;
}

b,
em,
strong {
  font-style: normal;
  font-weight: bold;
  line-height: inherit;
}

:root {
  --landing-primary-color: #0d6efd;
  --landing-accent-color: #b92c2c;
  --landing-success-color: #2da8a8;
  --landing-info-color: #00B7E5;
  --landing-warning-color: #ff9900;
  --landing-danger-color: #b0120a;
  --landing-primary-light-color: #6ea8fe;
  --landing-accent-light-color: #d58080;
  --landing-success-light-color: #81cbcb;
  --landing-info-light-color: #66d4ef;
  --landing-warning-light-color: #ffc266;
  --landing-danger-light-color: #d0716c;
  --landing-primary-dark-color: #084298;
  --landing-accent-dark-color: #6f1a1a;
  --landing-success-dark-color: #1b6565;
  --landing-info-dark-color: #006e89;
  --landing-warning-dark-color: #995c00;
  --landing-danger-dark-color: #6a0b06;
  --landing-gray-100-color: #f8f9fa;
  --landing-gray-200-color: #e9ecef;
  --landing-gray-300-color: #dee2e6;
  --landing-gray-400-color: #ced4da;
  --landing-gray-500-color: #adb5bd;
  --landing-gray-600-color: #6c757d;
  --landing-gray-700-color: #495057;
  --landing-gray-800-color: #343a40;
  --landing-gray-900-color: #212529;
  --landing-gray-dark-color: #333;
  --landing-black-color: #000;
  --landing-white-color: #fff;
  --landing-red-color: #b0120a;
  --landing-yellow-color: #fff000;
  --landing-orange-color: #f18f2c;
  --landing-green-color: #63a764;
  --landing-blue-color: #005fcc;
  --landing-pink-color: #ff0066;
  --landing-purple-color: #6f42c1;
  --landing-cyan-color: #17a2b8;
  --root-font-size: 16px;
  --landing-font-size: .875rem;
  --landing-font-size-medium: .75rem;
  --landing-font-size-small: .6875rem;
  --landing-font-family: system-ui, -apple-system, Robot, "Helvetica Neue", "Helvetica", "Hiragino Sans", "Noto Sans CJK JP", "メイリオ", Meiryo, Arial, sans-serif;
  --landing-font-color: #333;
  --landing-spacer: 1.5rem;
  --landing-spacer-small: 0.75rem;
  --landing-spacer-large: 3rem;
  --landing-shadow: 0.25rem 0 0.625rem #3a486133;
  --landing-shadow-small: 0 0.125rem 0.25rem #3a486133;
  --landing-shadow-large: 0 1rem 3rem #3a486133;
  --landing-grid-columns: 12;
  --landing-grid-gap: 1rem;
  --landing-border-color: #dee2e6;
  --landing-border-radius: .75rem;
  --landing-transition: .2s ease-out;
  --landing-dialog-gutter: 1.25rem;
  --landing-dialog-max-width: 500px;
  --landing-placeholder-color: #6c757d;
  --landing-focus-ring: 0 0 0 0.25rem #005fcc40;
  --landing-focus-ring-small: 0 0 0 0.125rem #005fcc40;
  --landing-radio-gutter: .5rem;
  --landing-checkbox-gutter: .5rem;
}

html {
  font-size: var(--root-font-size);
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: auto;
}

body {
  background-color: var(--landing-white-color);
  color: var(--landing-font-color);
  font-family: var(--landing-font-family);
  font-size: 1rem;
  min-width: 100%;
  height: auto;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.landing {
  background-color: var(--landing-gray-200-color);
  display: grid;
  place-content: center;
  place-items: center;
  width: 100vw;
  height: 100vh;
}

.landing-dialog {
  margin: var(--landing-dialog-gutter) auto;
  position: relative;
  width: clamp(18.75rem, 7.25rem + 57.5vw, 24.5rem);
  height: calc(100% - var(--landing-dialog-gutter) * 2);
}

.landing-container {
  background-color: var(--landing-white-color);
  border-radius: 1rem;
  box-shadow: var(--landing-shadow-large);
  display: flex;
  flex-direction: column;
  max-height: 100%;
  position: relative;
}

.landing-header {
  padding: var(--landing-dialog-gutter);
  position: relative;
}
.landing-header h1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.landing-header h1::before {
  content: "";
  background-color: var(--landing-accent-color);
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpolygon points="22,7.3 22,4.9 13.9,4.9 14.9,2.5 12.1,2.5 11.1,4.9 2,4.9 2,7.3 10.1,7.3 9.5,8.8 2,8.8 2,11.2 8.5,11.2 7.9,12.8 2,12.8 2,15.2 7,15.2 6.3,16.7 2,16.7 2,19.1 5.4,19.1 4.3,21.5 7.2,21.5 8.2,19.1 22,19.1 22,16.7 9.2,16.7 9.7,15.2 22,15.2 22,12.8 10.8,12.8 11.4,11.2 22,11.2 22,8.8 12.3,8.8 13,7.3 "/%3e%3c/svg%3e');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 0.75rem;
  width: 5rem;
  height: 5rem;
}
.landing-header h1 span {
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 700;
}

.landing-contents {
  flex: 1 1 auto;
  overflow-y: auto;
  padding: var(--landing-dialog-gutter);
}

.landing-field + .landing-field {
  margin-top: calc(var(--landing-dialog-gutter) * 1.5);
}

.landing-footer {
  padding: var(--landing-dialog-gutter);
}

.btn-landing {
  background-color: var(--landing-primary-color);
  border-radius: 0.25rem;
  box-shadow: var(--landing-shadow-small);
  color: var(--landing-white-color);
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: 700;
  padding: 0.75em 2em;
  position: relative;
  text-align: center;
  appearance: none;
  text-decoration: none;
  transition: background-color var(--landing-transition), color var(--landing-transition);
  vertical-align: middle;
  white-space: nowrap;
}
@media (hover: hover) and (pointer: fine) {
  .btn-landing:hover {
    background-color: var(--landing-primary-dark-color);
  }
}
.btn-landing.expanded {
  display: block;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.btn-landing-sub {
  background-color: var(--landing-white-color);
  border: 1px solid var(--landing-gray-500-color);
  border-radius: 0.25rem;
  box-shadow: var(--landing-shadow-small);
  color: var(--landing-gray-dark-color);
  cursor: pointer;
  display: inline-block;
  font-size: var(--landing-font-size);
  font-weight: 700;
  padding: 0.75em 2em;
  position: relative;
  text-align: center;
  appearance: none;
  text-decoration: none;
  transition: background-color var(--landing-transition), border var(--landing-transition);
  vertical-align: middle;
  white-space: nowrap;
}
@media (hover: hover) and (pointer: fine) {
  .btn-landing-sub:hover {
    border-color: var(--landing-gray-dark-color);
  }
}
.btn-landing-sub.expanded {
  display: block;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.landing-input {
  background-color: var(--landing-white-color);
  border: 1px solid var(--landing-gray-500-color);
  border-radius: 0.5rem;
  box-shadow: none;
  color: var(--landing-gray-dark-color);
  font-size: var(--landing-font-size);
  line-height: 1.6;
  padding: 0.75em 1em;
  width: 100%;
  transition: border-color var(--landing-transition), background-color var(--landing-transition);
}
.landing-input::placeholder {
  font-size: 0.85em;
  line-height: 1;
  transition: color var(--landing-transition);
}
.landing-input::-moz-placeholder {
  color: var(--landing-placeholder-color);
  opacity: 1;
}
.landing-input:-ms-input-placeholder {
  color: var(--landing-placeholder-color);
}
.landing-input::-webkit-input-placeholder {
  color: var(--landing-placeholder-color);
}
.landing-input::-ms-expand {
  background-color: var(--landing-white-color);
  border: 0;
}
.landing-input:focus, .landing-input:focus:not(:placeholder-shown) {
  background-color: var(--landing-white-color);
  border-color: var(--landing-base-color);
  box-shadow: var(--landing-focus-ring);
  outline: 0;
}
.landing-input:focus::placeholder, .landing-input:focus:not(:placeholder-shown)::placeholder {
  color: transparent;
}
.landing-input:not(:placeholder-shown) {
  background-color: var(--landing-white-color);
  border: 1px solid var(--landing-gray-500-color);
}

.landing-label {
  display: block;
  font-size: var(--landing-font-size-medium);
  font-weight: 500;
  margin-bottom: 0.5em;
}

hr {
  border-top: 0;
  border-bottom: 1px solid #ced4da;
  border-left: 0;
  border-right: 0;
  clear: both;
  margin: var(--landing-dialog-gutter) 0;
  width: 100%;
  height: 0;
}

.reissue {
  margin-top: 4px;
  text-align: right;
}
.reissue a {
  font-size: var(--landing-font-size-medium);
}

.login-validate {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 150px;
  left: 0;
  width: 100%;
}

.login-error-message {
  animation: fadeIn var(--landing-transition) forwards;
  color: var(--landing-danger-color);
  display: inline-block;
  font-size: var(--landing-font-size-medium);
  font-weight: 500;
  opacity: 0;
}
.login-error-message.success {
  color: var(--landing-success-color);
  font-weight: 700;
}

.registration {
  background-color: var(--landing-gray-200-color);
  display: grid;
  place-content: center;
  place-items: center;
  width: 100vw;
  height: 100vh;
}

.registration-wrapper {
  margin: var(--landing-dialog-gutter) auto;
  position: relative;
  width: clamp(18.75rem, 0.75rem + 90vw, 30rem);
  height: calc(100% - var(--landing-dialog-gutter) * 2);
}

label.block-based-checkbox {
  display: flex;
  margin: 0 auto;
}
label.block-based-checkbox [type=radio] + div,
label.block-based-checkbox [type=checkbox] + div {
  background-color: var(--landing-gray-100-color);
  border: 2px solid var(--landing-gray-100-color);
  border-radius: 6px;
  cursor: pointer;
  color: var(--landing-gray-500-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: var(--landing-font-size);
  padding: 1em;
  position: relative;
  transition: background-color var(--landing-transition), border var(--landing-transition);
}
@media (hover: hover) and (pointer: fine) {
  label.block-based-checkbox [type=radio] + div:hover,
  label.block-based-checkbox [type=checkbox] + div:hover {
    border-color: var(--landing-gray-200-color);
  }
}
label.block-based-checkbox [type=radio] + div::before,
label.block-based-checkbox [type=checkbox] + div::before {
  content: "";
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23adb5bd"%3e%3cpath d="M6,3h12c1.7,0,3,1.3,3,3v12c0,1.7-1.3,3-3,3H6c-1.7,0-3-1.3-3-3V6C3,4.3,4.3,3,6,3zM6,5C5.4,5,5,5.4,5,6v12c0,0.6,0.4,1,1,1h12c0.6,0,1-0.4,1-1V6c0-0.6-0.4-1-1-1H6z"/%3e%3c/svg%3e');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  margin-right: 0.5em;
  width: 1.5em;
  height: 1.5em;
  transition: background var(--landing-transition);
}
label.block-based-checkbox [type=radio]:checked + div,
label.block-based-checkbox [type=checkbox]:checked + div {
  background-color: var(--landing-white-color);
  border: 2px solid var(--landing-info-color);
  color: var(--landing-gray-700-color);
  font-weight: 700;
}
label.block-based-checkbox [type=radio]:checked + div::before,
label.block-based-checkbox [type=checkbox]:checked + div::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%2300B7E5"%3e%3cpath d="M18,3c1.7,0,3,1.3,3,3v12c0,1.7-1.3,3-3,3H6c-1.7,0-3-1.3-3-3V6c0-1.7,1.3-3,3-3H18z M16.5,8L10,14.4L7.5,12c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1l3,3c0.3,0.3,0.8,0.3,1.1,0l7-7c0.3-0.3,0.3-0.8,0-1.1C17.2,7.7,16.8,7.7,16.5,8z"/%3e%3c/svg%3e');
}
label.block-based-checkbox [type=radio] + div::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23333"%3e%3cpath d="M12,2c5.5,0,10,4.5,10,10s-4.5,10-10,10S2,17.5,2,12S6.5,2,12,2z M12,3.5c-4.7,0-8.5,3.8-8.5,8.5s3.8,8.5,8.5,8.5s8.5-3.8,8.5-8.5S16.7,3.5,12,3.5z"/%3e%3c/svg%3e');
}
label.block-based-checkbox [type=radio]:checked + div::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M12,6c3.3,0,6,2.7,6,6c0,3.3-2.7,6-6,6c-3.3,0-6-2.7-6-6C6,8.7,8.7,6,12,6z"/%3e%3c/svg%3e');
}

input[type=checkbox],
input[type=radio] {
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  margin: 0;
  outline: none;
}
input[type=checkbox]:disabled + label,
input[type=radio]:disabled + label {
  color: var(--landing-gray-500-color);
  cursor: not-allowed;
}

.checkbox-group,
.radio-group {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.checkbox-group.vertical,
.radio-group.vertical {
  flex-direction: column;
}

.checkbox-group {
  gap: calc(var(--landing-checkbox-gutter) * 2);
}
.checkbox-group.vertical {
  gap: var(--landing-checkbox-gutter);
}

.radio-group {
  gap: calc(var(--landing-checkbox-gutter) * 2);
}
.radio-group.vertical {
  gap: var(--landing-checkbox-gutter);
}

.label-checkbox,
.label-radio {
  display: flex;
}
.label-checkbox input:focus + label::before,
.label-radio input:focus + label::before {
  box-shadow: var(--landing-focus-ring);
  outline: 0;
}
.label-checkbox label,
.label-radio label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-size: var(--landing-font-size);
}
.label-checkbox label::before,
.label-radio label::before {
  content: "";
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid var(--landing-gray-500-color);
  display: inline-block;
  margin-right: 0.5em;
  width: 1em;
  height: 1em;
  transition: background var(--landing-transition);
}

.label-checkbox input[type=checkbox] + label::before {
  border-radius: 4px;
}
.label-checkbox input[type=checkbox]:checked + label::before {
  background-color: var(--landing-primary-color);
  border-color: var(--landing-primary-color);
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M18,3c1.7,0,3,1.3,3,3v12c0,1.7-1.3,3-3,3H6c-1.7,0-3-1.3-3-3V6c0-1.7,1.3-3,3-3H18z M16.5,8L10,14.4L7.5,12c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1l3,3c0.3,0.3,0.8,0.3,1.1,0l7-7c0.3-0.3,0.3-0.8,0-1.1C17.2,7.7,16.8,7.7,16.5,8z"/%3e%3c/svg%3e');
}

.label-radio input[type=radio] + label::before {
  border-radius: 50%;
}
.label-radio input[type=radio]:checked + label::before {
  background-color: var(--landing-primary-color);
  border-color: var(--landing-primary-color);
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M12,6c3.3,0,6,2.7,6,6c0,3.3-2.7,6-6,6c-3.3,0-6-2.7-6-6C6,8.7,8.7,6,12,6z"/%3e%3c/svg%3e');
}

.checkbox-switch-wrapper .switch {
  display: none;
}
.checkbox-switch-wrapper .switch + label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  position: relative;
  user-select: none;
}
.checkbox-switch-wrapper .switch + label::before {
  content: "";
  background-color: #adb5bd;
  border-radius: 500px;
  display: block;
  height: 15px;
  margin-right: 8px;
  transition: background-color 0.125s ease-out;
  width: 25px;
}
.checkbox-switch-wrapper .switch + label::after {
  content: "";
  background-color: #fff;
  border-radius: 13px;
  box-shadow: 0 3px 1px 0 rgba(37, 34, 71, 0.05), 0 2px 2px 0 rgba(37, 34, 71, 0.1), 0 3px 3px 0 rgba(37, 34, 71, 0.05);
  display: block;
  height: 13px;
  position: absolute;
  top: 1px;
  left: 1px;
  transition: transform 0.125s ease-out;
  width: 13px;
}
.checkbox-switch-wrapper .switch + label .switch-x-text {
  display: block;
  font-size: var(--landing-font-size-medium);
  line-height: 1;
}
.checkbox-switch-wrapper .switch + label .switch-x-toggletext {
  display: block;
  line-height: 1;
  font-size: var(--landing-font-size-medium);
  font-weight: 700;
  height: 1em;
  overflow: hidden;
  position: relative;
  width: 2em;
}
.checkbox-switch-wrapper .switch + label .switch-x-unchecked,
.checkbox-switch-wrapper .switch + label .switch-x-checked {
  left: 0;
  position: absolute;
  top: 0;
  transition: transform 0.125s ease-out, opacity 0.125s ease-out;
}
.checkbox-switch-wrapper .switch + label .switch-x-unchecked {
  color: #6c757d;
  opacity: 1;
  transform: none;
}
.checkbox-switch-wrapper .switch + label .switch-x-checked {
  color: #00AC73;
  opacity: 0;
  transform: translate3d(0, 100%, 0);
}
.checkbox-switch-wrapper .switch + label .switch-x-hiddenlabel {
  position: absolute;
  visibility: hidden;
}
.checkbox-switch-wrapper .switch:checked + label::before {
  background-color: #00AC73;
}
.checkbox-switch-wrapper .switch:checked + label::after {
  transform: translate3d(10px, 0, 0);
}
.checkbox-switch-wrapper .switch:checked + label .switch-x-unchecked {
  opacity: 0;
  transform: translate3d(0, -100%, 0);
}
.checkbox-switch-wrapper .switch:checked + label .switch-x-checked {
  opacity: 1;
  transform: none;
}

.form-btn {
  background-color: var(--landing-primary-color);
  border: 1px solid transparent;
  border-radius: 2em;
  box-shadow: var(--landing-box-shadow-small);
  cursor: pointer;
  color: var(--landing-white-color);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  outline: none;
  padding: 1em 2em;
  text-align: center;
  text-decoration: none;
  transition: background-color var(--landing-transition), border-color var(--landing-transition);
  white-space: nowrap;
}
@media (hover: hover) and (pointer: fine) {
  .form-btn:hover {
    background-color: var(--landing-primary-dark-color);
    color: var(--landing-white-color);
  }
}
.form-btn.outline {
  background-color: var(--landing-white-color);
  border: 1px solid var(--landing-gray-700-color);
  color: var(--landing-gray-dark-color);
}
@media (hover: hover) and (pointer: fine) {
  .form-btn.outline:hover {
    background-color: var(--landing-gray-700-color);
    color: var(--landing-white-color);
  }
}
.form-btn.expanded {
  display: block;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.form-field + .form-field {
  margin-top: calc(var(--landing-grid-gap) * 2);
}

.input-parent {
  display: flex;
  align-items: center;
  position: relative;
}
.input-parent + .input-parent {
  margin-top: var(--landing-grid-gap);
}
.input-parent .grid {
  flex: 1;
}

.icon-calendar {
  display: flex;
  pointer-events: none;
  position: absolute;
  top: 50%;
  right: 3em;
  transform: translateY(-50%);
}
.icon-calendar::before {
  content: "";
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ced4da"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
}
.icon-calendar.no-delete {
  right: 1rem;
}

.delete-calendar {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236c757d"%3e%3cpath d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M17,13H7v-2h10V13z"/%3e%3c/svg%3e');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  margin-left: 8px;
  width: 1.5em;
  height: 1.5em;
  transition: background-image var(--landing-transition);
}
@media (hover: hover) and (pointer: fine) {
  .delete-calendar:hover {
    background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ced4da"%3e%3cpath d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M17,13H7v-2h10V13z"/%3e%3c/svg%3e');
  }
}

.input {
  background-color: var(--landing-gray-200-color);
  border: 1px solid transparent;
  border-radius: 0.5rem;
  box-shadow: none;
  color: var(--landing-gray-dark-color);
  font-size: var(--landing-font-size);
  line-height: 1.6;
  padding: 0.75em 1em;
  width: 100%;
  transition: border-color var(--landing-transition), background-color var(--landing-transition);
}
.input + .input {
  margin-top: 8px;
}
.input:disabled {
  background-color: var(--landing-gray-100-color);
  cursor: not-allowed;
}
.input::placeholder {
  font-size: 0.75em;
  line-height: 1;
  transition: color var(--landing-transition);
}
.input::-moz-placeholder {
  color: var(--landing-placeholder-color);
  opacity: 1;
}
.input:-ms-input-placeholder {
  color: var(--landing-placeholder-color);
}
.input::-webkit-input-placeholder {
  color: var(--landing-placeholder-color);
}
.input::-ms-expand {
  background-color: var(--landing-white-color);
  border: 0;
}
.input[type=file] {
  font-size: var(--landing-font-size);
  overflow: hidden;
  padding: 0.5em 0.5em;
}
.input[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.input::file-selector-button {
  background-color: var(--1emgray-100-color);
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  color: var(--1emgray-dark-color);
  margin: -0.75em -1em;
  margin-inline-end: 0.75em;
  padding: 1em 0.75em;
  pointer-events: none;
}
.input:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--landing-gray-200-color);
}
.input::-webkit-file-upload-button {
  background-color: var(--1emgray-100-color);
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  color: var(--1emgray-dark-color);
  margin: -0.75em -1em;
  margin-inline-end: 0.75em;
  padding: 1em 0.75em;
  pointer-events: none;
}
.input:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: var(--landing-gray-200-color);
}
.input:focus, .input:focus:not(:placeholder-shown) {
  background-color: var(--landing-white-color);
  border-color: var(--landing-base-color);
  box-shadow: var(--landing-focus-ring);
  outline: 0;
}
.input:focus::placeholder, .input:focus:not(:placeholder-shown)::placeholder {
  color: transparent;
}
.input:not(:placeholder-shown) {
  background-color: var(--landing-gray-100-color);
  border: 1px solid var(--landing-gray-500-color);
}
textarea.input {
  font-family: var(--landing-font-family);
  line-height: 1.6;
  height: auto;
  overflow-wrap: break-word;
  padding: 1em;
  resize: vertical;
  width: 100%;
}

.input.calendar {
  cursor: pointer;
}
.input.error {
  border: 1px solid var(--landing-red-color);
}

.form-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
}
.form-container + .form-container {
  margin-top: 2rem;
}

.form-title {
  background-color: var(--landing-title-background-color);
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.5em;
}
.form-title .title {
  background-color: transparent;
  color: var(--landing-gray-dark-color);
  font-size: var(--landing-font-size-medium);
  font-weight: 700;
}
.form-title .optional,
.form-title .required {
  border-radius: 3px;
  color: var(--landing-white-color);
  font-size: var(--landing-font-size-small);
  font-weight: 700;
  line-height: 1;
  margin: 0 0.5rem;
  padding: 0.4em 0.4em calc(0.4em - 1px);
}
.form-title .optional {
  background-color: var(--landing-success-color);
}
.form-title .required {
  background-color: var(--landing-danger-color);
}

select.select {
  background-color: var(--landing-white-color);
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236c757d"%3e%3cpath d="M17.9,10c1.1,0,1.6-1.1,0.9-1.8l-5.5-5.6c-0.7-0.7-1.9-0.7-2.6,0L5.2,8.2C4.5,8.9,5,10,6.1,10H17.9z"/%3e%3cpath d="M6.1,14c-1.1,0-1.6,1.1-0.9,1.8l5.5,5.6c0.7,0.7,1.9,0.7,2.6,0l5.5-5.6c0.7-0.7,0.1-1.8-0.9-1.8H6.1z"/%3e%3c/svg%3e');
  background-position: right 0.8em center;
  background-repeat: no-repeat;
  background-size: 0.75em;
  border: 1px solid var(--landing-gray-500-color);
  border-radius: 0.5rem;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font-size: var(--landing-font-size-medium);
  outline: none;
  padding: 0.75em 2.25em 0.75em 1em;
  position: relative;
  transition: border-color var(--landing-transition);
  width: auto;
}
select.select::-moz-focus-inner {
  border: 0;
}
select.select:focus {
  background-color: var(--landing-white-color);
  border-color: var(--landing-base-color);
  box-shadow: var(--landing-focus-ring);
  outline: 0;
}
select.select:disabled {
  background-color: var(--landing-gray-100-color);
  cursor: not-allowed;
  color: var(--landing-gray-600-color);
  border-color: var(--landing-gray-400-color);
}
select.select.error {
  border-color: var(--landing-red-color);
}

label.selection-label {
  display: flex;
  margin: 0 auto;
}
label.selection-label + label.selection-label {
  margin-top: 1rem;
}
label.selection-label [type=radio] + .selection-label-container,
label.selection-label [type=checkbox] + .selection-label-container {
  background-color: #f8f9fa;
  border: 3px solid #e9ecef;
  border-radius: 8px;
  cursor: pointer;
  color: #333;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1em;
  position: relative;
  transition: background-color var(--landing-transition), border var(--landing-transition);
}
@media (hover: hover) and (pointer: fine) {
  label.selection-label [type=radio] + .selection-label-container:hover,
  label.selection-label [type=checkbox] + .selection-label-container:hover {
    background-color: #e9ecef;
  }
}
label.selection-label [type=radio] + .selection-label-container:before,
label.selection-label [type=checkbox] + .selection-label-container:before {
  content: "";
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23adb5bd"%3e%3cpath d="M12,2c5.5,0,10,4.5,10,10s-4.5,10-10,10S2,17.5,2,12S6.5,2,12,2z M12,3.5c-4.7,0-8.5,3.8-8.5,8.5s3.8,8.5,8.5,8.5s8.5-3.8,8.5-8.5S16.7,3.5,12,3.5z"/%3e%3c/svg%3e');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  flex: 0 0 auto;
  margin-right: 0.7em;
  width: 1.5em;
  height: 1.5em;
  transition: background var(--landing-transition);
}
label.selection-label [type=radio]:checked + .selection-label-container,
label.selection-label [type=checkbox]:checked + .selection-label-container {
  background-color: #f8f9fa;
  border-color: var(--landing-primary-color);
  color: #333;
}
label.selection-label [type=radio]:checked + .selection-label-container::before,
label.selection-label [type=checkbox]:checked + .selection-label-container::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%230d6efd"%3e%3cpath d="M12,6c3.3,0,6,2.7,6,6c0,3.3-2.7,6-6,6c-3.3,0-6-2.7-6-6C6,8.7,8.7,6,12,6z"/%3e%3c/svg%3e');
}

.selection-inner .title {
  font-size: var(--landing-font-size);
  font-weight: 700;
}
.selection-inner .selection-notes {
  border-top: 1px solid #dee2e6;
  font-family: sans-serif;
  font-size: var(--landing-font-size-small);
  margin-top: 1em;
  padding-top: 1em;
}

.validate {
  display: inline-block;
  margin: 0 0 0 0.5em;
  visibility: hidden;
}
.validate.valid {
  visibility: visible;
}
.validate .error {
  animation: fadeIn var(--landing-transition);
  color: #b0120a;
  display: inline-block;
  font-size: var(--landing-font-size-medium);
  font-weight: 700;
  position: relative;
}

.viva-verify-wrapper {
  background-color: var(--landing-gray-100-color);
  opacity: 0;
  overflow: auto;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  transition: opacity var(--landing-transition), visibility var(--landing-transition);
  width: 100%;
  height: 100vh;
  height: 100dvh;
  z-index: 999;
}
.viva-verify-wrapper[aria-hidden=false] {
  opacity: 1;
  visibility: visible;
}

.viva-verify-container {
  background-color: var(--landing-white-color);
  box-shadow: var(--landing-box-shadow);
  border-radius: 12px;
  margin: 1em auto;
  padding: 1.5rem;
  width: min(92%, 720px);
}
@media (min-width: 768px) {
  .viva-verify-container {
    padding: 3rem;
  }
}

h2.verify-heading {
  color: var(--landing-primary-color);
  font-size: 1.25em;
  font-weight: 700;
  margin-bottom: 2em;
  text-align: center;
}

.verify-section:not(:first-child) {
  border-top: 1px solid var(--landing-gray-200-color);
  margin: 1em 0 0;
  padding: 1em 0 0;
}

.verify-section-title {
  font-size: 1.25em;
  font-weight: 700;
  margin-bottom: 0.5em;
}

.verify-contents {
  text-align: left;
}
.verify-contents + .verify-contents {
  border-top: 1px solid var(--landing-gray-200-color);
  margin-top: 1em;
  padding-top: 1em;
}

.verify-title {
  color: var(--landing-primary-color);
  font-size: var(--landing-font-size-medium);
  font-weight: 700;
  margin-bottom: 0.875em;
  padding-bottom: 0.5em;
  position: relative;
}
.verify-title::before {
  content: "";
  background-color: var(--landing-primary-color);
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 2rem;
  height: 2px;
}

.verify-sub-title {
  font-size: var(--landing-font-size-small);
  font-weight: 700;
  margin-bottom: 0.5em;
}

.verify-value + .verify-sub-title {
  margin-top: 1em;
}
.verify-value span {
  display: inline-block;
}
.verify-value span:not(:first-child) {
  margin-left: 0.5em;
}

.verify-btn-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 4rem auto 0;
  width: 200px;
}
@media (min-width: 768px) {
  .verify-btn-wrapper {
    flex-direction: row;
    gap: 1rem;
    margin-top: 4rem;
    width: 490px;
  }
}

.return-column, .exec-column {
  flex: 1;
  width: 100%;
}
@media (min-width: 768px) {
  .return-column, .exec-column {
    flex: 1;
    width: auto;
  }
}

.return-column {
  margin-top: 1rem;
  order: 1;
}
@media (min-width: 768px) {
  .return-column {
    margin-top: 0;
    order: 0;
  }
}

.exec-column {
  order: 0;
}
@media (min-width: 768px) {
  .exec-column {
    order: 1;
  }
}