@charset 'UTF-8';
@use 'sass:map';
@use '../../global/functions' as *;



$primary-color: #0d6efd !default;
$accent-color : #b92c2c !default;
$success-color: #2da8a8 !default;
$info-color   : #00B7E5 !default;
$warning-color: #ff9900 !default;
$danger-color : #b0120a !default;

$themes: (
  'primary': $primary-color,
  'accent' : $accent-color,
  'success': $success-color,
  'info'   : $info-color,
  'warning': $warning-color,
  'danger' : $danger-color
) !default;

// lighten
$prefix-light: '-light' !default;
$light-weight: 40% !default;
$themes-light: (
  'primary#{$prefix-light}': tint-color($primary-color, $light-weight),
  'accent#{$prefix-light}' : tint-color($accent-color, $light-weight),
  'success#{$prefix-light}': tint-color($success-color, $light-weight),
  'info#{$prefix-light}'   : tint-color($info-color, $light-weight),
  'warning#{$prefix-light}': tint-color($warning-color, $light-weight),
  'danger#{$prefix-light}' : tint-color($danger-color, $light-weight)
) !default;

// darken
$prefix-dark: '-dark' !default;
$dark-weight: 40% !default;
$themes-dark: (
  'primary#{$prefix-dark}': shade-color($primary-color, $dark-weight),
  'accent#{$prefix-dark}' : shade-color($accent-color, $dark-weight),
  'success#{$prefix-dark}': shade-color($success-color, $dark-weight),
  'info#{$prefix-dark}'   : shade-color($info-color, $dark-weight),
  'warning#{$prefix-dark}': shade-color($warning-color, $dark-weight),
  'danger#{$prefix-dark}' : shade-color($danger-color, $dark-weight)
) !default;



$red   : #b0120a !default;
$yellow: #fff000 !default;
$orange: #f18f2c !default;
$green : #63a764 !default;
$blue  : #005fcc !default;
$pink  : #ff0066 !default;
$purple: #6f42c1 !default;
$cyan  : #17a2b8 !default;

$commons: (
  'red'   : $red,
  'yellow': $yellow,
  'orange': $orange,
  'green' : $green,
  'blue'  : $blue,
  'pink'  : $pink,
  'purple': $purple,
  'cyan'  : $cyan
) !default;



$gray-100 : #f8f9fa !default;
$gray-200 : #e9ecef !default;
$gray-300 : #dee2e6 !default;
$gray-400 : #ced4da !default;
$gray-500 : #adb5bd !default;
$gray-600 : #6c757d !default;
$gray-700 : #495057 !default;
$gray-800 : #343a40 !default;
$gray-900 : #212529 !default;
$gray-dark: #333    !default;
$black    : #000    !default;
$white    : #fff    !default;

$grays: (
  'gray-100':  $gray-100,
  'gray-200':  $gray-200,
  'gray-300':  $gray-300,
  'gray-400':  $gray-400,
  'gray-500':  $gray-500,
  'gray-600':  $gray-600,
  'gray-700':  $gray-700,
  'gray-800':  $gray-800,
  'gray-900':  $gray-900,
  'gray-dark': $gray-dark,
  'black':     $black,
  'white':     $white
) !default;



// カラーの配列を作る

// テーマカラーの明度
$themes-luminance: map.merge($themes-light, $themes-dark);

// テーマカラー
$theme-colors: map.merge($themes, $themes-luminance);

// グレースケールと汎用カラーの配列
$basic-colors: map.merge($grays, $commons);

// テーマカラーを含めたすべての配列
$all-colors: map.merge($theme-colors, $basic-colors);
