@charset 'UTF-8';
@use '../global' as *;



.viva-verify-wrapper {
  background-color: var(--#{$prefix}gray-100-color);
  opacity: 0;
  overflow: auto;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  transition: opacity var(--#{$prefix}transition), visibility var(--#{$prefix}transition);
  width: 100%;
  height: 100vh;
  height: 100dvh;
  z-index: 999;

  &[aria-hidden="false"] {
    opacity: 1;
    visibility: visible;
  }
}



.viva-verify-container {
  background-color: var(--#{$prefix}white-color);
  box-shadow: var(--#{$prefix}box-shadow);
  border-radius: 12px;
  margin: 1em auto;
  padding: 1.5rem;
  width: min(92%, 720px);
  @include media(){
    padding: 3rem;
  }
}



h2.verify-heading {
  color: var(--#{$prefix}primary-color);
  font-size: 1.25em;
  font-weight: 700;
  margin-bottom: 2em;
  text-align: center;
}



.verify-section {
  &:not(:first-child) {
    border-top: 1px solid var(--#{$prefix}gray-200-color);
    margin: 1em 0 0;
    padding: 1em 0 0;
  }
}



.verify-section-title {
  font-size: 1.25em;
  font-weight: 700;
  margin-bottom: .5em;
}



.verify-contents {
  text-align: left;
  & + & {
    border-top: 1px solid var(--#{$prefix}gray-200-color);
    margin-top: 1em;
    padding-top: 1em;
  }
}



.verify-title {
  color: var(--#{$prefix}primary-color);
  font-size: var(--#{$prefix}font-size-medium);
  font-weight: 700;
  margin-bottom: .875em;
  padding-bottom: .5em;
  position: relative;
  &::before {
    content: '';
    background-color: var(--#{$prefix}primary-color);
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 2rem;
    height: 2px;
  }
}



.verify-sub-title {
  font-size: var(--#{$prefix}font-size-small);
  font-weight: 700;
  margin-bottom: .5em;
}



.verify-value {
   & + .verify-sub-title {
    margin-top: 1em;
  }
  span {
    display: inline-block;
    &:not(:first-child) {
      margin-left: .5em;
    }
  }
}



.verify-btn-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 4rem auto 0;
  width: 200px;
  @include media(){
    flex-direction: row;
    gap: 1rem;
    margin-top: 4rem;
    width: 490px;
  }
}

.return-column, .exec-column {
  flex: 1;
  width: 100%;
  @include media(){
    flex: 1;
    width: auto;
  }
}

.return-column {
  margin-top: 1rem;
  order: 1;
  @include media(){
    margin-top: 0;
    order: 0;
  }
}

.exec-column {
  order: 0;
  @include media(){
    order: 1;
  }
}
