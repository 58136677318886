@charset 'UTF-8';
@use '../global' as *;



.form-btn {
  background-color: var(--#{$prefix}primary-color);
  border: 1px solid transparent;
  border-radius: 2em;
  box-shadow: var(--#{$prefix}box-shadow-small);
  cursor: pointer;
  color: var(--#{$prefix}white-color);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  outline: none;
  padding: 1em 2em;
  text-align: center;
  text-decoration: none;
  transition: background-color var(--#{$prefix}transition), border-color var(--#{$prefix}transition);
  white-space: nowrap;
  // Hover
  @include hover() {
    background-color: var(--#{$prefix}primary-dark-color);
    color: var(--#{$prefix}white-color);
  }
  // キャンセルとか
  &.outline {
    background-color: var(--#{$prefix}white-color);
    border: 1px solid var(--#{$prefix}gray-700-color);
    color: var(--#{$prefix}gray-dark-color);
    @include hover() {
      background-color: var(--#{$prefix}gray-700-color);
      color: var(--#{$prefix}white-color);
    }
  }
  // Expanded
  &.expanded {
    display: block;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
