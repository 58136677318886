@charset 'UTF-8';
@use '../global' as *;



label.selection-label {
  display: flex; // checkbox に高さを与えるため
  margin: 0 auto;
  //height: 100%;

  & + & {
    margin-top: 1rem;
  }

  [type='radio'],
  [type='checkbox'] {

    & + .selection-label-container {
      background-color: $gray-100;
      border: 3px solid $gray-200;
      border-radius: 8px;
      cursor: pointer;
      color: $gray-dark;
      display: flex;
      flex-direction: row;
      align-items: center;
      //height: 100%;
      padding: 1em;
      position: relative;
      transition: background-color var(--#{$prefix}transition), border var(--#{$prefix}transition);

      @include hover(){
        background-color: $gray-200;
      }
      &:before {
        content: '';
        background-image: set-svg( $radio, $gray-500 );
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        flex: 0 0 auto;
        margin-right: .7em;
        width: 1.5em;
        height: 1.5em;
        transition: background var(--#{$prefix}transition);
      }
    }

    &:checked + .selection-label-container {
      background-color: $gray-100;
      border-color: var(--#{$prefix}primary-color);
      color: $gray-dark;
      &::before {
        background-image: set-svg( $radio-checked, $primary-color );
      }
    }
  }
}



.selection-inner {
  .title {
    font-size: var(--#{$prefix}font-size);
    font-weight: 700;
  }
  .selection-notes {
    border-top: 1px solid $gray-300;
    font-family: sans-serif;
    font-size: var(--#{$prefix}font-size-small);
    margin-top: 1em;
    padding-top: 1em;
  }
}
