@charset 'UTF-8';
@use '../global' as *;



.checkbox-switch-wrapper {
  .switch {
    display: none;
    + label {
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      position: relative;
      user-select: none;
      &::before {
        content: '';
        background-color: $gray-500;
        border-radius: 500px;
        display: block;
        height: 15px;
        margin-right: 8px;
        transition: background-color .125s ease-out;
        width: 25px;
      }
      &::after {
        content: '';
        background-color: $white;
        border-radius: 13px;
        box-shadow: 0 3px 1px 0 rgba(37, 34, 71, .05), 0 2px 2px 0 rgba(37, 34, 71, .1), 0 3px 3px 0 rgba(37, 34, 71, .05);
        display: block;
        height: 13px;
        position: absolute;
        top: 1px;
        left: 1px;
        transition: transform .125s ease-out;
        width: 13px;
      }

      .switch-x-text {
        display: block;
        font-size: var(--#{$prefix}font-size-medium);
        line-height: 1;
      }

      .switch-x-toggletext {
        display: block;
        line-height: 1;
        font-size: var(--#{$prefix}font-size-medium);
        font-weight: 700;
        height: 1em;
        overflow: hidden;
        position: relative;
        width: 2em;
      }

      .switch-x-unchecked,
      .switch-x-checked {
        left: 0;
        position: absolute;
        top: 0;
        transition: transform .125s ease-out, opacity .125s ease-out;
      }

      .switch-x-unchecked {
        color: $gray-600;
        opacity: 1;
        transform: none;
      }

      .switch-x-checked {
        color: #00AC73;
        opacity: 0;
        transform: translate3d(0, 100%, 0);
      }

      .switch-x-hiddenlabel {
        position: absolute;
        visibility: hidden;
      }
    }

    &:checked {
      + label {
        &::before {
          background-color: #00AC73;
        }
        &::after {
          transform: translate3d(10px, 0, 0);
        }
        .switch-x-unchecked {
          opacity: 0;
          transform: translate3d(0, -100%, 0);
        }
        .switch-x-checked {
          opacity: 1;
          transform: none;
        }
      }
    }

  }
}
