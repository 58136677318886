@charset 'UTF-8';
@use '../global' as *;



select.select {
  background-color: var(--#{$prefix}white-color);
  background-image: set-svg( $caret-up-down, $gray-600 );
  background-position: right .8em center;
  background-repeat: no-repeat;
  background-size: .75em;
  border: 1px solid var(--#{$prefix}gray-500-color);
  border-radius: .5rem;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font-size: var(--#{$prefix}font-size-medium);
  outline: none;
  padding: .75em 2.25em .75em 1em;
  position: relative;
  transition: border-color var(--#{$prefix}transition);
  width: auto;

  &::-moz-focus-inner {
    border: 0;
  }

  &:focus {
    background-color: var(--#{$prefix}white-color);
    border-color: var(--#{$prefix}base-color);
    box-shadow: var(--#{$prefix}focus-ring);
    outline: 0;
  }

  &:disabled {
    background-color: var(--#{$prefix}gray-100-color);
    cursor: not-allowed;
    color: var(--#{$prefix}gray-600-color);
    border-color: var(--#{$prefix}gray-400-color);
  }

  // error
  &.error {
    border-color: var(--#{$prefix}red-color);
  }
}
