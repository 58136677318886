@charset 'UTF-8';
@use '../global' as *;



.form-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;

  & + & {
    margin-top: 2rem;
  }
}



.form-title {
  background-color: var(--#{$prefix}title-background-color);
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: .5em;

  // 項目名
  .title {
    background-color: transparent;
    color: var(--#{$prefix}gray-dark-color);
    font-size: var(--#{$prefix}font-size-medium);
    font-weight: 700;
  }

  // 必須と任意
  .optional,
  .required {
    border-radius: 3px;
    color: var(--#{$prefix}white-color);
    font-size: var(--#{$prefix}font-size-small);
    font-weight: 700;
    line-height: 1;
    margin: 0 .5rem;
    padding: .4em .4em calc( .4em - 1px );
  }
  // 任意
  .optional {
    background-color: var(--#{$prefix}success-color);
  }
  // 必須
  .required {
    background-color: var(--#{$prefix}danger-color);
  }
}
