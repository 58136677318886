@charset 'UTF-8';
@use './global' as *;



.registration {
  background-color: var(--#{$prefix}gray-200-color);
  display: grid;
  place-content: center;
  place-items: center;
  width: 100vw;
  height: 100vh;
}



.registration-wrapper {
  margin: var(--#{$prefix}dialog-gutter) auto;
  position: relative;
  width: clamp(18.75rem, 0.75rem + 90vw, 30rem);
  height: calc(100% - var(--#{$prefix}dialog-gutter) * 2);
}
