@charset 'UTF-8';
@use '../global' as *;



.validate {
  display: inline-block;
  margin: 0 0 0 .5em;
  visibility: hidden;

  &.valid {
    visibility: visible;
  }

  .error {
    animation: fadeIn var(--#{$prefix}transition);
    color: $red;
    display: inline-block;
    font-size: var(--#{$prefix}font-size-medium);
    font-weight: 700;
    position: relative;
  }
}
