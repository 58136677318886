@charset 'UTF-8';
@use './global' as *;



.landing-input {
  background-color: var(--#{$prefix}white-color);
  border: 1px solid var(--#{$prefix}gray-500-color);
  border-radius: .5rem;
  box-shadow: none;
  color: var(--#{$prefix}gray-dark-color);
  font-size: var(--#{$prefix}font-size);
  line-height: 1.6;
  padding: .75em 1em;
  width: 100%;
  transition: border-color var(--#{$prefix}transition), background-color var(--#{$prefix}transition);
  // プレースホルダー
  &::placeholder {
    font-size: .85em;
    line-height: 1;
    transition: color var(--#{$prefix}transition);
  }
  &::-moz-placeholder {
    color: var(--#{$prefix}placeholder-color);
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: var(--#{$prefix}placeholder-color);
  }
  &::-webkit-input-placeholder {
    color: var(--#{$prefix}placeholder-color);
  }
  &::-ms-expand {
    background-color: var(--#{$prefix}white-color);
    border: 0;
  }
  // フォーカス
  &:focus,
  &:focus:not(:placeholder-shown) {
    background-color: var(--#{$prefix}white-color);
    border-color: var(--#{$prefix}base-color);
    box-shadow: var(--#{$prefix}focus-ring);
    outline: 0;
    &::placeholder {
      color: transparent;
    }
  }
  // 入力済み
  &:not(:placeholder-shown) {
    background-color: var(--#{$prefix}white-color);
    border: 1px solid var(--#{$prefix}gray-500-color);
  }
}
