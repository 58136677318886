@charset 'UTF-8';
@use './global' as *;



.btn-landing {
  background-color:  var(--#{$prefix}primary-color);
  border-radius: .25rem;
  box-shadow: var(--#{$prefix}shadow-small);
  color: var(--#{$prefix}white-color);
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: 700;
  padding: .75em 2em;
  position: relative;
  text-align: center;
  appearance: none;
  text-decoration: none;
  transition: background-color var(--#{$prefix}transition), color var(--#{$prefix}transition);
  vertical-align: middle;
  white-space: nowrap;
  @include hover(){
    background-color: var(--#{$prefix}primary-dark-color);
  }
  // Expanded
  &.expanded {
    display: block;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}



.btn-landing-sub {
  background-color: var(--#{$prefix}white-color);
  border: 1px solid var(--#{$prefix}gray-500-color);
  border-radius: .25rem;
  box-shadow: var(--#{$prefix}shadow-small);
  color: var(--#{$prefix}gray-dark-color);
  cursor: pointer;
  display: inline-block;
  font-size: var(--#{$prefix}font-size);
  font-weight: 700;
  padding: .75em 2em;
  position: relative;
  text-align: center;
  appearance: none;
  text-decoration: none;
  transition: background-color var(--#{$prefix}transition), border var(--#{$prefix}transition);
  vertical-align: middle;
  white-space: nowrap;
  @include hover(){
    border-color: var(--#{$prefix}gray-dark-color);
  }
  // Expanded
  &.expanded {
    display: block;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
