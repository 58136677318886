@charset 'UTF-8';
@use './global' as *;



.landing-label {
  display: block;
  font-size: var(--#{$prefix}font-size-medium);
  font-weight: 500;
  margin-bottom: .5em;
}



hr {
  border-top: 0;
  border-bottom: 1px solid $gray-400;
  border-left: 0;
  border-right: 0;
  clear: both;
  margin: var(--#{$prefix}dialog-gutter) 0;
  width: 100%;
  height: 0;
}



.reissue {
  margin-top: 4px;
  text-align: right;
  a {
    font-size: var(--#{$prefix}font-size-medium);
  }
}



.login-validate {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 150px;
  left: 0;
  width: 100%;
}

.login-error-message {
  animation: fadeIn var(--#{$prefix}transition) forwards;
  color: var(--#{$prefix}danger-color);
  display: inline-block;
  font-size: var(--#{$prefix}font-size-medium);
  font-weight: 500;
  opacity: 0;
  &.success {
    color: var(--#{$prefix}success-color);
    font-weight: 700;
  }
}
