@charset 'UTF-8';
@use '../global' as *;



label.block-based-checkbox {
  display: flex; // checkbox に高さを与えるため
  margin: 0 auto;

  [type='radio'],
  [type='checkbox'] {
    & + div {
      background-color: var(--#{$prefix}gray-100-color);
      border: 2px solid var(--#{$prefix}gray-100-color);
      border-radius: 6px;
      cursor: pointer;
      color: var(--#{$prefix}gray-500-color);
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: var(--#{$prefix}font-size);
      padding: 1em;
      position: relative;
      transition: background-color var(--#{$prefix}transition), border var(--#{$prefix}transition);

      @include hover() {
        border-color: var(--#{$prefix}gray-200-color);
      }

      &::before {
        content: '';
        background-image: set-svg( $checkbox, $gray-500 );
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        margin-right: .5em;
        width: 1.5em;
        height: 1.5em;
        transition: background var(--#{$prefix}transition);
      }
    }
    &:checked + div {
      background-color: var(--#{$prefix}white-color);
      border: 2px solid var(--#{$prefix}info-color);
      color: var(--#{$prefix}gray-700-color);
      font-weight: 700;
      &::before {
        background-image: set-svg( $checkbox-checked, $info-color );
      }
    }
  }
  // Radio
  [type='radio'] {
    & + div {
      &::before {
        background-image: set-svg($radio, $gray-dark);
      }
    }
    &:checked + div {
      &::before {
        background-image: set-svg($radio-checked, $white);
      }
    }
  }
}
