@charset 'UTF-8';
@use './global' as *;



html {
  font-size: var(--root-font-size);
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: auto;
}



body {
  background-color: var(--#{$prefix}white-color);
  color: var(--#{$prefix}font-color);
  font-family: var(--#{$prefix}font-family);
  font-size: 1rem;
  min-width: 100%;
  height: auto;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
  @media only screen and ( -webkit-min-device-pixel-ratio: 2 ), ( min-resolution: 2dppx ) {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}



.landing {
  background-color: var(--#{$prefix}gray-200-color);
  display: grid;
  place-content: center;
  place-items: center;
  width: 100vw;
  height: 100vh;
}



.landing-dialog {
  margin: var(--#{$prefix}dialog-gutter) auto;
  position: relative;
  width: clamp(18.75rem, 7.25rem + 57.5vw, 24.5rem);
  height: calc(100% - var(--#{$prefix}dialog-gutter) * 2);
}



.landing-container {
  background-color: var(--#{$prefix}white-color);
  //background: linear-gradient(0deg, rgba(0,194,255,1) 0%, rgba(158,251,255,1) 50%, rgba(255,255,255,1) 100%);
  border-radius: 1rem;
  box-shadow: var(--#{$prefix}shadow-large);
  display: flex;
  flex-direction: column;
  max-height: 100%;
  position: relative;
}



.landing-header {
  padding: var(--#{$prefix}dialog-gutter);
  position: relative;
  h1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    &::before {
      content: '';
      background-color: var(--#{$prefix}accent-color);
      background-image: set-svg($kotobuki, $white);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 60%;
      border-radius: 50%;
      display: inline-block;
      margin-bottom: .75rem;
      width: 5rem;
      height: 5rem;
    }
    span {
      display: inline-block;
      font-size: 1.5rem;
      font-weight: 700;
    }
  }
}



.landing-contents {
  flex: 1 1 auto;
  overflow-y: auto;
  padding: var(--#{$prefix}dialog-gutter);
}



.landing-field {
  & + & {
    margin-top: calc(var(--#{$prefix}dialog-gutter) * 1.5);
  }
}



.landing-footer {
  padding: var(--#{$prefix}dialog-gutter);
}
