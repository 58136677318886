@charset 'UTF-8';
@use '../global' as *;



.form-field {
  & + & {
    margin-top: calc(var(--#{$prefix}grid-gap) * 2);
  }
}



.input-parent {
  // カレンダーアイコン表示のために Flex 指定
  display: flex;
  align-items: center;
  position: relative;
  & + & {
    margin-top: var(--#{$prefix}grid-gap);
  }

  .grid {
    flex: 1;
  }
}



// カレンダーアイコンの表示
.icon-calendar {
  display: flex;
  pointer-events: none;
  position: absolute;
  top: 50%;
  right: 3em;
  transform: translateY(-50%);
  &::before {
    content: '';
    background-image: set-svg($calendar, $gray-400);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
  }
  // Delete アイコンが無いとき
  &.no-delete {
    right: 1rem;
  }
}



// カレンダーの入力値を削除するボタン
.delete-calendar {
  background-image: set-svg($remove, $gray-600);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  margin-left: 8px;
  width: 1.5em;
  height: 1.5em;
  transition: background-image var(--#{$prefix}transition);
  @include hover() {
    background-image: set-svg($remove, $gray-400);
  }
}
