@charset 'UTF-8';
@use './global' as *;



:root {
  // Colors
  @each $name, $color in $all-colors {
    --#{$prefix}#{$name}-color: #{$color};
  }

  --root-font-size: 16px;

  --#{$prefix}font-size:        .875rem;    // Default 14px
  --#{$prefix}font-size-medium: .75rem; // 中間サイズ 12px
  --#{$prefix}font-size-small:  .6875rem;  // 最小サイズ 11px
  --#{$prefix}font-family: system-ui, -apple-system, Robot, 'Helvetica Neue', 'Helvetica', 'Hiragino Sans', 'Noto Sans CJK JP', 'メイリオ', Meiryo, Arial, sans-serif;
  --#{$prefix}font-color: #{$gray-dark};

  --#{$prefix}spacer: #{$spacer};
  --#{$prefix}spacer-small: #{$spacer-small};
  --#{$prefix}spacer-large: #{$spacer-large};

  --#{$prefix}shadow: #{$shadow};
  --#{$prefix}shadow-small: #{$shadow-small};
  --#{$prefix}shadow-large: #{$shadow-large};

  --#{$prefix}grid-columns: 12;
  --#{$prefix}grid-gap: 1rem;

  --#{$prefix}border-color: #{$gray-300};
  --#{$prefix}border-radius: .75rem;

  --#{$prefix}transition: .2s ease-out;

  --#{$prefix}dialog-gutter: 1.25rem;
  --#{$prefix}dialog-max-width: 500px;

  --#{$prefix}placeholder-color: #6c757d;
  --#{$prefix}focus-ring: #{$focus-ring};
  --#{$prefix}focus-ring-small: #{$focus-ring-small};
  --#{$prefix}radio-gutter: .5rem;
  --#{$prefix}checkbox-gutter: .5rem;
}
