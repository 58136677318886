@charset 'UTF-8';
@use '../global' as *;



input[type='checkbox'],
input[type='radio'] {
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  margin: 0;
  outline: none;

  &:disabled + label {
    color: var(--#{$prefix}gray-500-color);
    cursor: not-allowed;
  }
}





.checkbox-group,
.radio-group {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  // 縦並び
  &.vertical {
    flex-direction: column;
  }
}

.checkbox-group {
  gap: calc(var(--#{$prefix}checkbox-gutter) * 2);
  &.vertical {
    gap: var(--#{$prefix}checkbox-gutter);
  }
}

.radio-group {
  gap: calc(var(--#{$prefix}checkbox-gutter) * 2);
  &.vertical {
    gap: var(--#{$prefix}checkbox-gutter);
  }
}





.label-checkbox,
.label-radio {
  display: flex;

  input {
    &:focus + label {
      &::before {
        box-shadow: var(--#{$prefix}focus-ring);
        outline: 0;
      }
    }
  }

  label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-size: var(--#{$prefix}font-size);
    &::before {
      content: '';
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border: 1px solid var(--#{$prefix}gray-500-color);
      display: inline-block;
      margin-right: .5em;
      width: 1em;
      height: 1em;
      transition: background var(--#{$prefix}transition);
    }
  }
}



// Checkbox
.label-checkbox {
  input[type="checkbox"] {
    + label {
      &::before {
        border-radius: 4px;
      }
    }
    &:checked + label {
      &::before {
        background-color: var(--#{$prefix}primary-color);
        border-color: var(--#{$prefix}primary-color);
        background-image: set-svg( $checkbox-checked, $white );
      }
    }
  }
}



// Radio
.label-radio {
  input[type="radio"] {
    + label {
      &::before {
        border-radius: 50%;
      }
    }
    &:checked + label {
      &::before {
        background-color: var(--#{$prefix}primary-color);
        border-color: var(--#{$prefix}primary-color);
        background-image: set-svg( $radio-checked, $white );
      }
    }
  }
}
