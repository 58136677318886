@charset 'UTF-8';
@use '../global' as *;



.input {
  background-color: var(--#{$prefix}gray-200-color);
  border: 1px solid transparent;
  border-radius: .5rem;
  box-shadow: none;
  color: var(--#{$prefix}gray-dark-color);
  font-size: var(--#{$prefix}font-size);
  line-height: 1.6;
  padding: .75em 1em;
  width: 100%;
  transition: border-color var(--#{$prefix}transition), background-color var(--#{$prefix}transition);

  & + & {
    margin-top: 8px;
  }

  // Disabled
  &:disabled {
    background-color: var(--#{$prefix}gray-100-color);
    cursor: not-allowed;
  }

  // プレースホルダー
  &::placeholder {
    font-size: .75em;
    line-height: 1;
    transition: color var(--#{$prefix}transition);
  }
  &::-moz-placeholder {
    color: var(--#{$prefix}placeholder-color);
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: var(--#{$prefix}placeholder-color);
  }
  &::-webkit-input-placeholder {
    color: var(--#{$prefix}placeholder-color);
  }
  &::-ms-expand {
    background-color: var(--#{$prefix}white-color);
    border: 0;
  }

  // File input
  &[type="file"] {
    font-size: var(--#{$prefix}font-size);
    overflow: hidden;
    padding: .5em .5em;

    &:not(:disabled):not([readonly]) {
      cursor: pointer;
    }
  }

  &::file-selector-button {
    @include file-selector-button(1em, .75em);
  }
  &:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: var(--#{$prefix}gray-200-color);
  }

  &::-webkit-file-upload-button {
    @include file-selector-button(1em, .75em);
  }
  &:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: var(--#{$prefix}gray-200-color);
  }

  // フォーカス
  &:focus,
  &:focus:not(:placeholder-shown) {
    background-color: var(--#{$prefix}white-color);
    border-color: var(--#{$prefix}base-color);
    box-shadow: var(--#{$prefix}focus-ring);
    outline: 0;
    &::placeholder {
      color: transparent;
    }
  }

  // 入力済み
  &:not(:placeholder-shown) {
    background-color: var(--#{$prefix}gray-100-color);
    border: 1px solid var(--#{$prefix}gray-500-color);
  }

  // テキストエリア
  @at-root textarea#{&} {
    font-family: var(--#{$prefix}font-family);
    line-height: 1.6;
    height: auto;
    overflow-wrap: break-word;
    padding: 1em;
    resize: vertical;
    width: 100%;
  }

  // カレンダー入力時
  &.calendar {
    cursor: pointer;
  }

  // error
  &.error {
    border: 1px solid var(--#{$prefix}red-color);
  }
}
